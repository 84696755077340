<template>
  <div>
    <the-app-bar />
    <div class="container py-16">
      <div class="row">
          <div class="col-md-4 ">
        <h4>Category</h4>
        <ul class="space-y-4">
          <li><router-link to="#">About Oonpay</router-link></li>
          <li><router-link to="/create-wallet">Wallet Creation</router-link></li>
          <li><router-link to="/add-money">Adding Money</router-link></li>
          <li><router-link to="/debit-card">Visa Debit Card</router-link></li>
          <li><router-link to="/send-money">Sending Moeny</router-link></li>
          <li><router-link to="#">Account management</router-link></li>
          <li><router-link to="#">Adding & Removing Friend</router-link></li>
        </ul>
      </div>
      <div class="col-md-8">
        <slot />
      </div>
      </div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "../footer.vue";
import TheAppBar from "../NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer },
};
</script>

<style scoped>
#footer {
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
}

a{
    
     display: inline-block;
    color: #858585;
    font-size: 1.1em;
    padding: 6px 15px 6px 0;
    line-height: 20px;
    margin-left: 0;
    font-size: 1rem;

}
</style>
